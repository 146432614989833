import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {
  Container,
  Hidden,
  CssBaseline,
  Divider,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core"
import { makeStyles, ThemeProvider } from "@material-ui/core/styles"
import { useSelector } from "react-redux"
import Layout from "../components/Layout"
import WhyPortugalStickyCard from "../components/WhyPortugalStickyCard"
import investTheme from "../components/Layout/investTheme"
import Textfield from "../components/TextField"
import Footer from "../components/Footer"
import Zigzag from "../components/Zigzag"
import InvestStickyNav from "../components/InvestStickyNav"
import { SEO } from "../components/SEO"

const useStyles = makeStyles(theme => ({
  titleSection: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(12.5),
      marginBottom: theme.spacing(5),
    },
  },
  pageSubtitle: {
    fontSize: "1rem",
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(2),
    },
  },
  titleImage: {
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(4.5),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
    },
  },
  sectionWrapper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    },
  },
  dim: {
    color: "rgba(255,255,255,0.6)",
  },
  gridWrapper: {
    paddingTop: theme.spacing(4.5),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(15),
    },
  },
  cardOverline: {
    fontSize: "0.75rem",
    paddingBottom: theme.spacing(1.5),
  },
  cardLocation: {
    paddingTop: theme.spacing(1),
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}))

export const query = graphql`
  query($locale: String!) {
    allContentfulWhyPortugalPageModel(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          pageTitle
          pageDescription {
            pageDescription
          }
          individualCardsTitles
          individualCardsDescriptions
          individualCardsLocations
          companyCardsDescriptions
          seoTitle
          seoDescription
        }
      }
    }

    allContentfulInvestPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          querySectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          querySectionDescription {
            childMarkdownRemark {
              html
            }
          }
          stickyCardTitle
          stickyCardServicesList
        }
      }
    }
    file(name: { eq: "PortugalInvest" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const WhyPortugal = ({
  location,
  pageContext,
  data: {
    allContentfulWhyPortugalPageModel,
    allContentfulInvestPageModel,
    file,
  },
}) => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const classes = useStyles()
  const [isCompanies, setIsCompanies] = useState(false)
  const {
    querySectionImage,
    querySectionDescription,
    stickyCardServicesList,
  } = allContentfulInvestPageModel.edges[0].node

  const {
    pageTitle,
    pageDescription,
    individualCardsTitles,
    individualCardsDescriptions,
    individualCardsLocations,
    companyCardsDescriptions,
    seoTitle,
  } = allContentfulWhyPortugalPageModel.edges[0].node
  const mainImage = file.childImageSharp

  const stickyCardLinks = [
    { label: translations?.goldenVisa, to: "/GoldenVisa" },
    { label: translations?.header?.menu?.invest, to: "/invest" },
    {
      label: translations?.investPages?.realEstateOpportunities,
      to: "/RealEstateOpportunities",
    },
    {
      label: translations?.investPages?.investmentOpportunities,
      to: "/InvestmentOpportunities",
    },
  ]
  console.log("Invest ", translations?.header)

  const sections = [
    "golden-visa-section",
    "work-section",
    "investment-options-section",
    "visa-services-section",
  ]

  const handleTabChange = state => {
    setIsCompanies(state)
  }

  const individualCards = individualCardsTitles.map((title, index) => (
    <Grid item xs={12} md={4} key={index}>
      <Card style={{ height: "100%" }}>
        <CardContent className={classes.cardContent}>
          <Box>
            <Typography className={classes.cardOverline}>{title}</Typography>
            <Typography variant="h6">
              {individualCardsDescriptions[index]}
            </Typography>
          </Box>
          <Typography className={classes.cardLocation}>
            {individualCardsLocations[index]}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ))

  const companyCards = companyCardsDescriptions.map((description, index) => (
    <Grid item xs={12} md={4} key={index}>
      <Card style={{ height: "100%" }}>
        <CardContent>
          <Typography variant="h6">{description}</Typography>
        </CardContent>
      </Card>
    </Grid>
  ))

  return (
    <Layout
      backgroundcolor={"black"}
      Textcolor={"white"}
      selected={"invest"}
      button={"#FAD578"}
      location={location}
      pageContext={pageContext}
    >
      <SEO
        seoData={{
          title: seoTitle,
        }}
      />
      <div className={locale === "zh" && "chinese-version"}>
        <ThemeProvider theme={investTheme}>
          <CssBaseline />
          <Container>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={5} className={classes.titleSection}>
                    <Typography variant="h1">{pageTitle}</Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.pageSubtitle}
                    >
                      {pageDescription.pageDescription}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7} className={classes.titleImage}>
                    <Img fluid={mainImage.fluid} />
                  </Grid>
                </Grid>
                <section id="section-tabs">
                  <Box display="flex">
                    <Box>
                      <Button
                        onClick={() => handleTabChange(false)}
                        classes={isCompanies ? { label: classes.dim } : null}
                      >
                        {translations?.investPages?.forIndividuals}
                      </Button>
                      <Box style={{ textAlign: "center" }}>
                        <Zigzag
                          color={
                            isCompanies
                              ? null
                              : investTheme.palette.secondary.main
                          }
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => handleTabChange(true)}
                        classes={!isCompanies ? { label: classes.dim } : null}
                      >
                        {translations?.investPages?.forCompanies}{" "}
                      </Button>
                      <Box style={{ textAlign: "center" }}>
                        <Zigzag
                          color={
                            isCompanies
                              ? investTheme.palette.secondary.main
                              : null
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Grid container spacing={3} className={classes.gridWrapper}>
                    {isCompanies ? companyCards : individualCards}
                  </Grid>
                </section>
              </Grid>
              <Hidden smDown>
                <Grid item md={4}>
                  <WhyPortugalStickyCard
                    title={translations?.investPages?.whyPortugal}
                    servicesList={stickyCardServicesList}
                    links={stickyCardLinks}
                    sections={sections}
                    handleTabChange={handleTabChange}
                    selected="/WhyPortugal"
                    individualsTitle={translations?.investPages?.forIndividuals}
                    companiesTitle={translations?.investPages?.forCompanies}
                  />
                </Grid>
              </Hidden>
              <InvestStickyNav
                title={translations?.investPages?.whyPortugal}
                links={stickyCardLinks}
                sections={sections}
                handleTabChange={handleTabChange}
                individualsTitle={translations?.investPages?.forIndividuals}
                companiesTitle={translations?.investPages?.forCompanies}
              />
            </Grid>
            <Divider />
          </Container>
          <Textfield
            title={translations?.sendUsEnquiry}
            description={querySectionDescription.querySectionDescription}
            locale={pageContext.locale}
            customImage={querySectionImage}
            defaultValue="Investment"
            cardVariant="contained"
          />
          <Container>
            <Divider />
          </Container>
          <Footer hidePartners />
        </ThemeProvider>
      </div>
    </Layout>
  )
}

export default WhyPortugal
